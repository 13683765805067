<template>
  <dialog class="c_external_link_modal">
    <div class="c_external_link_modal__header">
      <IconSVG handle="logo_kodak" :alt="$t('Kodak Logo')" />
    </div>
    <div class="c_external_link_modal__body">
      <h2 class="c_2022--h2_graphik">{{ $t('Leaving site header') }}</h2>
      <div class="c_2022--body c_text--graphik_medium">
        <p>
          {{ $t('Leaving site redirect message') }}
        </p>
      </div>
      <div
        class="c_2022--body c_2022--body-sm"
        v-if="disclaimers.length"
        v-html="disclaimers?.[0]?.content">
      </div>
    </div>
    <div class="c_external_link_modal__footer c_2022--body c_2022--body-sm">
      <div v-if="showRedirectText">
        <p v-if="countdown > 0">
          {{ $t('Redirecting in') }} {{ countdown }} {{ $t('seconds') }}
        </p>
        <p v-else-if="countdown === 0">
          {{ $t('Redirecting now') }}
        </p>
      </div>
      <Button class="c_button_2022 c_button_2022__primary" :href="url" new-window @onClick="hideModal">
        {{ $t('Redirect Now') }}
      </Button>
    </div>
  </dialog>
</template>

<script>
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import disclaimerFootnote from 'Components/disclaimer_footnote/DisclaimerFootnote.vue';
import Button from 'Components/button/Button.vue';

export default {
  name: 'ExternalLinkModal',
  components: {
    IconSVG,
    disclaimerFootnote,
    Button
  },
  props: {
    disclaimers: {
      type: Array,
      default: () => [],
    },
    url: { type: String, default: '' }
  },
  data() {
    return {
      countdown: 5,
      countdownTimer: null,
      showRedirectText: true,
    };
  },
  methods: {
    handleAutomaticRedirect() {
      if (!this.url) {
        console.error('No URL provided for redirection.');
        this.stopCountdown();
        return;
      }

      window.location.href = this.url;
      this.hideModal();
    },
    hideModal() {
      this.stopCountdown();
      this.$emit('hideExternalLinkModal');
    },
    startCountdown() {
      this.countdownTimer = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.handleAutomaticRedirect();
        }
      }, 1000);
    },
    stopCountdown() {
      this.showRedirectText = false;
      clearInterval(this.countdownTimer);
      this.countdownTimer = null;
    },
  },
  mounted() {
    this.startCountdown();
  },
  beforeDestroy() {
    this.stopCountdown();
  },
};
</script>

<style scoped lang="scss">
.c_external_link_modal {
  display: flex;
  flex-direction: column;
  max-width: 540px;
  background: #fff;
  padding: px_to_rem(60) px_to_rem(40);
  position: relative;
  text-align: left;
  .c_external_link_modal__header {
    .c_icon_svg {
      background-color: $color_yellow;
      color: $color_red;
      padding: px_to_rem(11);
      width: px_to_rem(62);
      display: flex;
      align-items: center;
    }
  }
  .c_external_link_modal__body {
    h2 {
      margin-top: px_to_rem(30);
      margin-bottom: 10px;
      color: #000;
      font-weight: bolder;
      @include font('graphik_bold');
    }
    & > div {
      margin-top: px_to_rem(20);
    }
  }
  .c_external_link_modal__footer {
    margin-top: px_to_rem(20);
    p {
      margin-bottom: px_to_rem(10);
    }
  }
  @media(max-width: $mq_sm_to_md + 'px') {
    padding: px_to_rem(40) px_to_rem(20);
  }
}
</style>
